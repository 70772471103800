import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setPlayerReleaseInit, setPlayerRelease } from '../redux/actions/releases'
import { Link } from 'react-router-dom'

const StaffPicks = (props) => {


  const [init, setInit] = useState(false)

  const [staffPicks, setStaffPicks] = useState(props.catalog.filter(r => r.staffPick))

  // let staffPicks = []
  useEffect(() => {
    if (!init) {
      if (props.catalog.length > 0) {
        props.catalog.forEach(release => {
          if (release.staffPick) {
            // staffPicks.push(release)
            setStaffPicks(old => [...old, release])
          }
        })
        setInit(true)
        // console.log("INIT PLAYER", staffPicks[0])
        // props.dispatch(setPlayerRelease(staffPicks[0], 0, 0))

      }
    }

  }, [props.catalog])
  // console.log(staffPicks[0])




  useEffect(() => {
    if(init){
      props.dispatch(setPlayerReleaseInit(staffPicks[0], 0, 0))
    }
  }, [init])


  // props.dispatch(setPlayerRelease(staffPicks[0], 0, 0))

  const handleTrackClick = (e) => {
    console.log(e.target.dataset.index)
    let trackIndex = parseInt(e.target.dataset.index);
    props.dispatch(setPlayerRelease(staffPicks[trackIndex], 0, 0))
  }

  return (
    <>
      <h3 className="is-size-2 mb-5 has-text-weight-bold">Staff Picks</h3>
      <div className="columns  is-multiline" style={{ display: "flex", flexWrap: "wrap" }}>
        {staffPicks.map((release, index) => (
          <div key={release.coverFront} className="column is-2 is-one-third-mobile">
            <div className="box fadein py-0 px-0 picks-box">
              <figure className="image  is-square">
                <img src={'https://couch.inchbyinch.eu/catalog/' + release._id + '/' + release.coverFront} alt={release.text}></img>
              </figure>
              <div className="staffpick-play" data-index={index} onClick={handleTrackClick}>
                <span className="icon is-large" data-index={index}>
                  <i className="fas fa-play" data-index={index}></i>
                </span>
              </div>
              <Link to={"/release/" + release._id}>
                <div className="staffpick-gtr">Go to Release</div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}


const mapStateToProps = (state) => ({
  catalog: state.releases.catalog
})

export default connect(mapStateToProps)(StaffPicks)