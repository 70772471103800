import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import Release from '../components/Release'
import Feedback from '../components/Feedback'
import axios from 'axios'
import {api} from '../api'
import ReactStars from 'react-stars'

const FeedbackPage = (props) => {
  let {id} = useParams()
  let pid = id.split('x')[0]

  let release = props.catalog.find(element => element.partNumber  === pid);

  let [feedback, setFeedback] = useState([])

  useEffect(()=>{
    if(release){
      axios(api + '/feedback/' + release._id)
        .then(res => {
          setFeedback(res.data)
          console.log(res.data)
        })

    }
  }, [release])

  if(release){
    return (
      <div>
        <div className="">
          <Release release={release} noborder={true} promo={true}/>
          <div className="columns">
                <div className="column bold">
                  Artist
                </div>
                <div className="column bold">
                  Message
                </div>
                <div className="column bold">
                  Rating
                
              </div>
        </div>
          
            {feedback.map((f)=>(
              <>
        <div className="columns">
                <div className="column">
                  {f.name}
                </div>
                <div className="column">
                  {f.message}
                </div>
                <div className="column">
                <ReactStars
          count={5}
          value={f.rating}
          size={28}
          edit={false}
          color1={"#d3d4d8"}
          color2={'#242c3e'} />
                
              </div>
        </div>
              </>
            ))}
 
        </div>
      </div>
    )
  } else {
    return (
      <div>... Searching Vinyl shelf</div>
    )
  }
} 

const mapStateToProps = (state) => ({
  catalog: state.releases.catalog
})


export default connect(mapStateToProps)(FeedbackPage)


