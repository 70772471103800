
const initialState = {authenticated: false, user: {}, orders: false, loading: false}

function auth(state = initialState, action) {
  switch (action.type) {
    case "LOGIN":
      return Object.assign({}, state, {
        user: action.user,
        authenticated: true,
        message: false
      })
    case "LOGOUT":
      console.log("logout reducer")
      return Object.assign({}, state, {
        user: {},
        authenticated:false,
        message: false,
        orders: false
      })
    case "SET_AUTH_MESSAGE":
      return Object.assign({}, state, {
        message: action.message
      })
    case "SET_ORDERS":
      return Object.assign({}, state, {
        orders: action.orders
      })
    case "SET_LOADING":
      return Object.assign({}, state, {
        loading: action.loading
      })
    default:
      return state
  }
}


export default auth