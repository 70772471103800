import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { filterByLabel } from '../redux/actions/filter'


const Label = (props) => {

  const history = useHistory()

  const handleLabelClick = (e) => {
    let l = e.target.dataset.label
    props.dispatch(filterByLabel(l))
    history.push('/releases')
  }

  return (
    <>
    <div className="columns is-multiline is-mobile">
      {props.labels.map(item => (
        <div key={item.slug} className="column is-one-third-tablet is-half-mobile is-2-desktop" data-label={item.slug} onClick={handleLabelClick}>
          <div className="label" data-label={item.slug}>
            <figure className="image is-square" data-label={item.slug}>
              <img data-label={item.slug} alt={item.slug} src={"https://couch.inchbyinch.eu/label/" + item.slug + '/' + item.logo}/>
            </figure>
            <p data-label={item.slug} className="is-size-6 has-text-centered">{item.name}</p>
          </div>
        </div>
      ))}
        </div>
        </>
      )
}

const mapStateToProps = (state) => ({
        labels: state.releases.labels
})

export default connect(mapStateToProps)(Label)