import React from 'react'
import Menu from './Menu'
import Navbar from './Navbar'
import Player from './Player'
import useBreakpoint from './useBreakpoint'


const Layout = (props) => {

  let brk = useBreakpoint()
  return (
    <>
      <Navbar />
      <div className="container  is-fluid mt-5">
        <div className="columns">
          <div className="column is-narrow is-hidden-mobile  pt-0">
            <Menu />
            {brk !== "mobile" && <Player/>}
          </div>
          <div className="column scrolly no-h-scroll pt-0">
            {props.children}
            <div className="white-space"></div>
          </div>
        </div>
      </div>
      <div className="mobile-player is-mobile">
          {brk === "mobile" && <Player/>}
      </div>

    </>
  )
}


export default Layout