
const initialState = {home: {}}

function cms(state = initialState, action) {
  switch (action.type) {
    case "SET_HOME":
      return Object.assign({}, state, {
        home: action.home,
      })

    default:
      return state
  }
}


export default cms