import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import inchbyinchapp from './redux/reducers/rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';


import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import { PersistGate } from 'redux-persist/integration/react'


import watch from 'redux-watch'
import axios from 'axios';

import { fetchCatalog, fetchGenres, fetchLabels } from './redux/actions/releases'
import { createSelector } from 'reselect'
import { fetchCart } from './redux/actions/cart';
import { fetchCms } from './redux/actions/cms';

import { api } from './api'
import { refreshLogin } from './redux/actions/auth';

import LogRocket from 'logrocket';
LogRocket.init('jrot1e/plasma-chat');


const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth'] // only auth will be persisted
};

const persistedReducer = persistReducer(persistConfig, inchbyinchapp)

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
let persistor = persistStore(store, null, () => {
  // refresh on rehydration of store
  store.dispatch(refreshLogin())

  if(store.getState().auth.user){
    LogRocket.identify(store.getState().auth.user.id, {
      name: store.getState().auth.user.id,
      email: store.getState().auth.user.mainEmail,
      customerType: store.getState().auth.user.type,
    });
  }
})

// starting actions
store.dispatch(fetchCms())
store.dispatch(fetchCatalog())
store.dispatch(fetchGenres())
store.dispatch(fetchLabels())


//  cart backend sync
const wishSelector = state => state.cart.wish
const cartSelector = state => state.cart.items
const userSelector = state => state.auth.user

const cartUpdateSelector = createSelector(
  cartSelector,
  wishSelector,
  userSelector,
  (cart, wish, customer) => ({ cart, wish, customer })
)
// disable sending empty cart on bootup

let cartInit = false

let w = watch(() => cartUpdateSelector(store.getState()))
store.subscribe(w((update) => {
  if (cartInit) {
    console.log("NEW CART TO SEND TO DB", update)
    // console.log("updating cart", update)
    // what is dis?
    //dirty: if cart is emtpy, do not send update
    if(Object.entries(update.cart).length == 0){
      if(store.getState().auth.authenticated){
        console.log("STILL AUTHENTICATED SENDING CART UPDATE")
        axios.post(api + '/cart/', update)
      } else {
        console.log("DO NOT SEND EMTPY CARDS")
      }
    } else {
      console.log("SEND CARD UPDATE TO DB")
      axios.post(api + '/cart/', update)
    }

  }
}))

// fetch cart and wish on auth
let u = watch(() => userSelector(store.getState()))
store.subscribe(u((update) => {
  // console.log("Logged in:", update)
  if (update) {
    // console.log("loading cart")
    store.dispatch(fetchCart(update))
    console.log("update card", update)
    setTimeout(() => {
      cartInit = true
    }, 1000)
  }


}))


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
