import { createReducer } from '@reduxjs/toolkit'

const initialState = {items: {}, wish: {}}
const cart = createReducer(initialState, {
  "SET_CART": (state, action) => {
    state.items = action.cart
  },
  "SET_WISH": (state, action) => {
    state.wish = action.wish
  },
  "ADD_CART_ITEM": (state, action) => {
    state.items[action.item.id] = action.item
  },
  "ADD_WISH_ITEM": (state, action) => {
    state.wish[action.item.id] = action.item
  },
  "DELETE_WISH_ITEM": (state, action) => {
    delete state.wish[action.item.id]
  },
  "DELETE_CART_ITEM": (state, action) => {
    delete state.items[action.item.id]
  },
  "INCREMENT_CART_ITEM": (state, action) => {
    state.items[action.itemId].amount++
  },
  "INCREMENT_WISH_ITEM": (state, action) => {
    state.wish[action.itemId].amount++
  },
  "DECREMENT_CART_ITEM": (state, action) => {
    state.items[action.itemId].amount--
  },
  "SET_AMOUNT_CART_ITEM": (state, action) => {
    state.items[action.itemId].amount = action.amount
  },
  "SET_AMOUNT_WISH_ITEM": (state, action) => {
    state.wish[action.itemId].amount = action.amount
  }
})


export default cart

