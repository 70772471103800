import React from 'react'


const Shop = () => {
  return (
    <>
    <iframe title="bandcamp" className="discogs" src="https://inchbyinchdistribution.bandcamp.com/merch"/>
    </>
  )
}

export default Shop