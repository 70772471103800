import axios from 'axios'
import {api} from '../../api'

function setHome(home) {
  return {
    type: "SET_HOME",
    home
  }
}


export function fetchCms() {
  return function (dispatch) {
    return axios(api + '/home').then(res => {
      
      dispatch(setHome(res.data))
    })
  }
}