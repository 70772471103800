import React from 'react';

const ShopMap = () => {

 
    return (
      <div >

        <iframe
        title="map"
          width="100%"
          height="450"
          frameBorder="0" 
          src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB_3rYpJeS3D8qSMypk6T7gNiKYsAvx6Vc
            &q=place_id:ChIJv9JTDev3pkcR_aUl9gbaWNE" allowFullScreen>
        </iframe>
        <div className="has-text-right">
        <br/>
        Merseburger Str. 95 <br/>
        04177 Leipzig<br/>
        </div>
      </div>
    );
  
}
 
export default ShopMap;