import React from 'react'
import ContentLoader from "react-content-loader"

const CoverLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={180}
    height={180}
    style={{ width: "100%" }}
    viewBox="0 0 100 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
  </ContentLoader>
)


const TrackLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={400}
    height={150}
    style={{ width: "100%" }}
    viewBox="0 0 400 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="10" cy="20" r="8" /> 
    <rect x="25" y="15" rx="5" ry="5" width="220" height="10" /> 
    <circle cx="10" cy="50" r="8" /> 
    <rect x="25" y="45" rx="5" ry="5" width="220" height="10" /> 
    <circle cx="10" cy="80" r="8" /> 
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" /> 
    <circle cx="10" cy="110" r="8" /> 
    <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)


const InfoLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={400}
    height={150}
    style={{ width: "100%" }}
    viewBox="0 0 400 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="25" y="3" rx="5" ry="5" width="220" height="10" /> 
    <rect x="26" y="21" rx="5" ry="5" width="220" height="19" /> 
    <rect x="24" y="119" rx="5" ry="5" width="77" height="24" /> 
    <rect x="26" y="48" rx="5" ry="5" width="75" height="13" /> 
    <rect x="25" y="103" rx="5" ry="5" width="77" height="12" /> 
    <rect x="300" y="130" rx="5" ry="5" width="77" height="12" /> 
    <rect x="299" y="6" rx="5" ry="5" width="77" height="12" />
  </ContentLoader>
)

const ReleaseLoader = () => {
  return (
    <div className="columns release mb-5">
      <div className="column is-narrow">
        <CoverLoader/>
      </div>
      <div className="column is-two-fifth">
        <InfoLoader/>
      </div>
      <div className="column is-two-fifth">
        <TrackLoader/>
      </div>
    </div>
  )
}


export default ReleaseLoader