import React from 'react'
import { connect } from 'react-redux'
import { filterByGenre, filterByMonth, resetFilter } from '../redux/actions/filter'



const ReleaseFilter = (props) => {

  const [month, setMonth] = React.useState(["All"])
  const [genre, setGenre] = React.useState("Genre")

  const handleSelectGenre = (e) => {
    let sgenre = e.target.value
    setGenre(sgenre)
    props.dispatch(filterByGenre(sgenre))
  }

  const handleSelectMonth = (e) => {
    let smonth = e.target.value
    setMonth([smonth])
    props.dispatch(filterByMonth(smonth))
  }

  const handleResetFilter = () => {
    props.dispatch(resetFilter())
  }

  return (
    <>
      <div className="mt-3"></div>
      <div className="flex-column">
        <div className={props.genres.length > 0 ? "select is-fullwidth" : "select is-loading is-fullwidth"}>
          <select onChange={handleSelectGenre} value={genre}>
            <option>Genre</option>
            <option>All</option>
            {props.genres.map(genre => (
              <option key={genre} value={genre}>{genre}</option>
            ))}
          </select>
        </div>
        <div className="select is-multiple mt-4 is-fullwidth">
          <select multiple size="13" onChange={handleSelectMonth} value={month}>
            <option value="All">All</option>
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
            <option value="06">June</option>
            <option value="07">July</option>
            <option value="08">August</option>
            <option value="09">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        {props.filter && (
          <button className="button mt-3 is-primary is-outlined" onClick={handleResetFilter}>
            <span>Reset all Filters</span>
            <span className="icon">
              <i className="fas fa-times"></i>
            </span>
          </button>
        )


        }
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  completeCatalog: state.releases.completeCatalog,
  genres: state.releases.genres,
  filter: state.releases.filter
})


export default connect(mapStateToProps)(ReleaseFilter)