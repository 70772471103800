import React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import Release from '../components/Release'
import Feedback from '../components/Feedback'

const ReleasePage = (props) => {
  let {id} = useParams()

  let release = props.catalog.find(element => element.id  === id);
  console.log(release?.description)

  if(release){
    return (
      <div>
        <div className="">
          <Release release={release} noborder={true}/>
        <div className="columns">
          <div className="column is-narrow">
            <figure className="image  is-256x256" style={{opacity: 0}}>
              <img src={'https://couch.inchbyinch.eu/catalog/' + release._id + '/' + release.coverFront} alt={release.text}></img>
            </figure>
          </div>
            <div className="column">
              <h3 className="is-size-5 has-text-weight-bold">Info</h3>
              <div style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html:release.description}}></div>
            </div>
            <div className="column">
              <Feedback release={release}/>
            </div>
            
        </div>
        </div>
      </div>
    )
  } else {
    return (
      <div>... Searching Vinyl shelf</div>
    )
  }
} 

const mapStateToProps = (state) => ({
  catalog: state.releases.catalog
})


export default connect(mapStateToProps)(ReleasePage)