import React from 'react'
import { connect } from 'react-redux'
import CartItem from '../components/CartItem'
import axios from 'axios'
import {setCart} from '../redux/actions/cart'
import {api} from '../api'

const Cart = (props) => {

  const [orderId, setOrderId] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false)
  const [error, setError] = React.useState(false)

  const calcTotal = () => {
    let t = 0
    // privat etc anpassen
    Object.values(props.cart).forEach(item => {
      let multiplicator = props.type === "privat" ? 1.33 : 1
      let p = (parseFloat(item["priceNet"])*multiplicator).toFixed(2) * item.amount
      t = t + p;
    })
    return t.toFixed(2) + " € + VAT"
  }


  const sendOrder = () => {
    setModal(true)
    let {user, cart} = props
    axios.post( api + '/order', {user, cart, date: new Date()}).then((res)=>{
      if(res.data.success){
        setOrderId(res.data.orderNumber)
        setLoading(false)
        props.dispatch(setCart({}))
      } else {
        setLoading(false)
        setError(true)
      }

    })
  }

  return (
    <div>
      <h2 className="is-size-3 has-text-weight-bold mb-3">Your Cart</h2>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th></th>
            <th>Cover</th>
            <th>Part #</th>
            <th>Catalog #</th>
            <th>Title</th>
            <th className="">Amount</th>
            <th className="has-text-right">Price</th>
            <th className="has-text-right">Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(props.cart).map((item => (
            <tr key={item.id}>
              <CartItem release={item} />
            </tr>
          )))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="has-text-right has-text-weight-bold">Total:</td>
            <td className="has-text-right	is-family-monospace has-text-weight-bold">{calcTotal()}</td>
          </tr>
        </tbody>
      </table>
      <p>Shipping costs will be added at your final invoice. You will receive an E-Mail with detailed Information.</p>
      <button className="button is-primary" disabled={Object.keys(props.cart).length === 0} onClick={sendOrder}>Place Order</button>

      <div className={modal? "modal is-active": "modal"}>
        <div className="modal-background" onClick={()=>setModal(false)}></div>
        <div className="modal-content">
          <div className="box">
          {loading && <p>Sending Order ...</p>}
          {!error && !loading && <p><span className="is-size-3">Thanks for your Order!</span> <br/> Your order has been placed and we are processing it now.<br/> Your order number is: <b>{orderId}</b> <br/> <span className="is-size-4"></span></p>}
          {error && !loading && <p>There was an Error processing your order. Please contact us!</p>}

          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={()=>setModal(false)}></button>
      </div>

    </div>
  )
}


const mapStateToProps = state => ({
  cart: state.cart.items,
  type: state.auth.user.type,
  user: state.auth.user
})


export default connect(mapStateToProps)(Cart)