import React from 'react'
import StaffPicks from '../components/StaffPicks'
import { connect } from 'react-redux'


const Home = (props) => {
  const [active, setActive] = React.useState("main")

  let page = props.cms[active]

  const handleTabClick = (e) => {
    let a = e.target.dataset.key
    setActive(a)
  }

  return (
    <>
    <div className="columns col-fix">
      <div className="column is-half pt-0">
      <div className="tabs pt-0 mt-0">
        <ul>
          <li className={active === "main" ? "is-active has-text-weight-bold is-size-5" : "is-size-5"} data-key="main" onClick={handleTabClick}><a href="#" data-key="main">Home</a></li>
          <li className={active === "distribution" ? "is-active has-text-weight-bold is-size-5" : "is-size-5"} data-key="distribution" onClick={handleTabClick}><a href="#" data-key="distribution">Inch by Inch Distribution</a></li>
          <li className={active === "store" ? "is-active has-text-weight-bold is-size-5" : "is-size-5"} data-key="store" onClick={handleTabClick}><a href="#" data-key="store">Our Record Store</a></li>
        </ul>
      </div>
      <p className="is-size-5 px-3">
        {page}
      </p>
      </div>
      <div className="column is-half side-image pt-0 is-hidden-mobile">
        {props.cms.leftImage && <img src={"https://couch.inchbyinch.eu/cms/home/" + props.cms.leftImage} alt="inchbyinch distribution"></img>}
        {props.cms.rightImage && <img src={"https://couch.inchbyinch.eu/cms/home/" + props.cms.rightImage} alt="inchbyinch-shop"></img>}
        
      </div>
    </div>

    <StaffPicks/>
    </>
  )
}


const mapStateToProps = state => ({
  cms: state.cms.home
})


export default connect(mapStateToProps)(Home)