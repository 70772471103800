import React from 'react'
import  {Link, useLocation } from "react-router-dom";
const Menu = (props) => {

  let history = useLocation();
  let page = history.pathname.split('/')[1]


  return (
    <aside className="menu">
      <ul className="menu-list">
        
        {/* <li><a href="/" className={props.page === "home" ? "is-active" : " "}>Home</a></li> */}
        <li><Link className={page === "" ? "is-active" : " "} to="/" onClick={props.onClose}>Home</Link></li>
      </ul>
      <p className="menu-label">
        Distribution
            </p>
      <ul className="menu-list">
        <li><Link to="/releases" onClick={props.onClose} className={page === "releases" ? "is-active" : " "}>Releases</Link></li>
        <li><Link to="/label" className={page === "label" ? "is-active" : " "} onClick={props.onClose}>Label</Link></li>

      </ul>
      <p className="menu-label">
        Recordstore
            </p>
      <ul className="menu-list">
        <li><Link to="/location" className={page === "location" ? "is-active" : " "} onClick={props.onClose}>Location</Link></li>
        <li><Link to="/shop" className={page === "shop" ? "is-active" : " "} onClick={props.onClose}>Shop</Link></li>
      </ul>
      <p className="menu-label">

      </p>
      <ul className="menu-list">
        <li><Link to="/contact" className={page === "contact" ? "is-active" : " "} onClick={props.onClose}>Contact</Link></li>
      </ul>
      
      <ul className="menu-list">
        <li><Link to="/imprint" className={page === "imprint" ? "is-active" : " "} onClick={props.onClose}>Imprint</Link></li>
      </ul>
    </aside>
  )
}

export default Menu