import React from 'react'
import { connect } from 'react-redux'
import { login } from '../redux/actions/auth'
import { useHistory } from 'react-router-dom'
import { api } from '../api'
import axios from 'axios'

const User = (props) => {
  const history = useHistory()
  const loginForm = React.useRef()
  const registerForm = React.useRef()

  const [type, setType] = React.useState("error")
  const [country, setCountry] = React.useState("1")
  const [billingCountry, setBillingCountry] = React.useState("1")
  const [countries, setCountries] = React.useState([])
  const [same, setSame] = React.useState(false)
  const [next, setNext] = React.useState(false)
  const [detailError, setDetailError] = React.useState("")
  const [error, setError] = React.useState("")
  const [firstStep, setFirstStep] = React.useState(false)
  const [old, setOld] = React.useState(false)
  const [oldCustomer, setOldCustomer] = React.useState({})

  React.useEffect(() => {
    async function fetchData() {
      let res = await axios(api + '/countries')
      setCountries(res.data)
    }
    fetchData()
  }, [])

  const handleLogin = async () => {
    var formData = new FormData(loginForm.current);
    // console.log(formData.getAll("type"));

    let ok = await axios({
      method: 'post',
      url: api + '/register',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    console.log(ok.data)

    if (ok.data.success) {
      if (ok.data.new) {
        setFirstStep(ok.data.firstStep)
        setNext(true)
      } else {
        // login?!
        props.dispatch(login({ mail: ok.data.customer.mainEmail, password: ok.data.customer.password }))
        setOldCustomer(ok.data.customer)
        setOld(true)
        // history.push('/releases')
      }
    } else {
      // todo: present error
      setError(ok.data.error)
    }
  }
  const handleDetails = async () => {
    var formData = new FormData(registerForm.current);
    // console.log(formData.getAll("type"));

    Object.keys(firstStep).forEach(k => {
      formData.append(k, firstStep[k])
    })

    let ok = await axios({
      method: 'post',
      url: api + '/register/activate',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    console.log(ok.data)

    if (ok.data.success) {
      history.push('/releases')
    } else {
      // todo: present error
      setDetailError(ok.data.error)
    }
  }

  const handleSelectType = (e) => {
    let smonth = e.target.value
    setType(smonth)
    // props.dispatch(filterByMonth(smonth))
  }
  const handleSelectCountry = (e) => {
    let smonth = e.target.value
    setCountry(smonth)
    // props.dispatch(filterByMonth(smonth))
  }
  const handleSelectBillingCountry = (e) => {
    let smonth = e.target.value
    setBillingCountry(smonth)
    // props.dispatch(filterByMonth(smonth))
  }

  const handleSame = e => {
    console.log(e.target.checked)
    setSame(e.target.checked)
  }



  return (
    <>
      {next && (
        <form ref={registerForm} onSubmit={(e) => e.preventDefault()} className="mw-400">
          <h2 className="is-size-2">Register Step 2</h2><br />
          <h2 className="is-size-4">Contact</h2>

          {(type === "privat" || type === "artist") && (
            <div className="field">
              <p className="control ">
                <input className="input" type="text" name="name" placeholder="Name" />
              </p>
            </div>
          )}


          {type === "shop" && (
            <>
              <div className="field">
                <p className="control ">
                  <input className="input" type="text" name="name" placeholder="Company Name" />
                </p>
              </div>
              <div className="field">
                <p className="control ">
                  <input className="input" type="text" name="name2" placeholder="Additional Name (optional)" />
                </p>
              </div>
              <div className="field">
                <p className="control ">
                  <input className="input" type="text" name="vatNumber" placeholder="VAT-ID" />
                </p>
              </div>
            </>
          )}

          <div className="field">
            <p className="control ">
              <input className="input" type="phone" name="phone" placeholder="Phone (optional)" />
            </p>
          </div>
          <br />

          {(type === "shop" || type === "privat") && (
            <>

              <h2 className="is-size-4">Shipping Address</h2>
              <div className="field">
                <p className="control ">
                  <input className="input" type="text" name="street" placeholder="Street" />
                </p>
              </div>
              <div className="field">
                <p className="control ">
                  <input className="input" type="text" name="city" placeholder="City" />
                </p>
              </div>
              <div className="field">
                <p className="control ">
                  <input className="input" type="text" name="zip" placeholder="ZIP" />
                </p>
              </div>
              <div className="field">
                <div className="select is-fullwidth">
                  <select name="country" onChange={handleSelectCountry} value={country} placeholder="Country" >
                    {countries.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}

                  </select>
                </div>
              </div>
              <div className="field">
                <label className="checkbox">
                  <input name="useBillingAddress" type="checkbox" defaultChecked={same} onChange={handleSame} />
                &nbsp;Use different Billing Address
              </label>
              </div>
              {same && (
                <>
                  <br />
                  <h2 className="is-size-4">Billing Address</h2>
                  <div className="field">
                    <p className="control ">
                      <input className="input" type="email" name="billingEmail" placeholder="Billing E-Mail (optional)" />
                    </p>
                  </div>
                  <div className="field">
                    <p className="control ">
                      <input className="input" type="text" name="billingStreet" placeholder="Street" />
                    </p>
                  </div>
                  <div className="field">
                    <p className="control ">
                      <input className="input" type="text" name="billingCity" placeholder="City" />
                    </p>
                  </div>
                  <div className="field">
                    <p className="control ">
                      <input className="input" type="text" name="billingZip" placeholder="ZIP" />
                    </p>
                  </div>
                  <div className="field">
                    <div className="select is-fullwidth">
                      <select name="billingCountry" onChange={handleSelectBillingCountry} value={billingCountry} placeholder="Country" >
                        {countries.map(c => (<option key={c.id} value={c.id} >{c.name}</option>))}

                      </select>
                    </div>
                  </div>
                </>
              )}
            </>
          )}


          <div className="field">
            <p className="control">
              <button className="button is-primary" onClick={handleDetails}>
                Submit & Activate your Account
            </button>
            </p>
          </div>
          <p className="has-text-danger">{detailError}</p>
        </form>
      )}
      {(!next && !old) && (
        <form ref={loginForm} onSubmit={(e) => e.preventDefault()} className="mw-400">
          <h2 className="is-size-2">Register Step 1</h2><br />
          <div className="field">
            <div className="select is-fullwidth">
              <select name="type" onChange={handleSelectType} value={type} >
                <option value="error" default disabled>Select Customer Type</option>
                <option value="artist">Artist</option>
                <option value="shop">Shop</option>
                <option value="privat">Privat</option>
              </select>
            </div>
          </div>
          <div className="field">
            <p className="control has-icons-left has-icons-right">
              <input className="input" type="email" name="mail" placeholder="Email" />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input className="input" type="password" name="password" placeholder="Password" autoComplete="off" />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left">
              <input className="input" type="password" name="password2" placeholder="Retype Password" autoComplete="off" />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <button className="button is-primary" onClick={handleLogin}>
                Register
            </button>
            </p>
          </div>
          <p className="has-text-danger">{error}</p>
        </form>
      )}
      {old && (
        <div>
          <h2 className="is-size-3 mt-3 has-text-weight-bold">We've already found you in our Customer Data!</h2>
          <p className="is-size-4">Here is what we have from you:</p><br />

          <div className="">
            <div className="columns">
              <div className="column">
                <h4 className="is-size-5 has-text-weight-bold">Contact</h4> <br />
                {oldCustomer.mainEmail} <br />
                {oldCustomer.surename} {oldCustomer.familyname} <br />
                {oldCustomer.name} <br />
                {oldCustomer.name2} <br />
                {oldCustomer.vatNumber} <br />
              </div>
              {(oldCustomer.type !== "artist" && oldCustomer.mainAddress) && (
                <div className="column">
                  <h4 className="is-size-5 has-text-weight-bold">Shipping Address</h4> <br />
                  {oldCustomer.mainEmail} <br />
                  {oldCustomer.mainAddress.street} <br />
                  {oldCustomer.mainAddress.city} <br />
                  {oldCustomer.mainAddress.zip} <br /><br />
                </div>
              )}
              {(oldCustomer.type !== "artist" && oldCustomer.billingAddress) && (
                <div className="column">
                  <h4 className="is-size-5 has-text-weight-bold">Billing Address</h4>
                  {oldCustomer.billingEmail} <br />
                  {oldCustomer.billingAddress.street} <br />
                  {oldCustomer.billingAddress.city} <br />
                  {oldCustomer.billingAddress.zip} <br /> <br />
                </div>
              )}
            </div>
          </div>
          <p>You can always edit your Data in your account settings!</p><br />
          <button className="button is-info">Take me to the Shop!</button>
        </div>
      )}

    </>
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  message: state.auth.message
})

export default connect(mapStateToProps)(User)