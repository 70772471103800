import React from 'react'
import { connect } from 'react-redux'
import { login, setAuthMessage } from '../redux/actions/auth'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import { api } from '../api'

const Reset = (props) => {
  let {key} = useParams()
  const history = useHistory()
  const loginForm = React.useRef()
  const resetForm = React.useRef()
  const [forgot, setForgot] = React.useState(false)

  const handleReset = async () => {
    var formData = new FormData(resetForm.current);
    formData.append('key', key)
    let ok = await axios.post(api + '/reset/password', formData);
    // console.log(ok)
    props.dispatch(setAuthMessage("Your password has been reset. Please Login!"));
    history.push('/login')
  }


  return (
    <>
      <h1 className="is-size-2">Reset Password</h1><br />
      <form ref={resetForm} onSubmit={(e) => e.preventDefault()} className=" mw-400">
        <div className="field">
          <p className="control has-icons-left">
            <input className="input" type="password" name="password" placeholder="Password" />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control">
            <button className="button is-primary" onClick={handleReset}>
              Reset Password
            </button>
          </p>
        </div>
        <p className="has-font-weight-bold">{props.message}</p>
      </form>

    </>
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  message: state.auth.message
})

export default connect(mapStateToProps)(Reset)