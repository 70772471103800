import axios from 'axios'
import {api} from '../../api'

export function setCart(cart) {
  return {
    type: "SET_CART",
    cart
  }
}

export function setWish(wish) {
  return {
    type: "SET_WISH",
    wish
  }
}



export function addWishItem(item, amount) {
  return function (dispatch, getState) {
    let state = getState()
    if(state.cart.wish[item.id]){
      dispatch({
        type: "SET_AMOUNT_WISH_ITEM",
        itemId:item.id,
        amount: state.cart.wish[item.id].amount + amount
      })
    } else {
      console.log("ADD NEW WISH ITEM")
      item.amount = amount ? amount : 1
      dispatch({
        type: "ADD_WISH_ITEM",
        item
      })
    }
  }
}


export function deleteWishItem(item) {
  return {
    type: "DELETE_WISH_ITEM",
    item
  }
}
export function deleteCartItem(item) {
  return {
    type: "DELETE_CART_ITEM",
    item
  }
}

export function setAmountCartItem(item, amount) {
  return {
    type: "SET_AMOUNT_CART_ITEM",
    itemId: item.id,
    amount
  }
}
export function setAmountWishItem(item, amount) {
  return {
    type: "SET_AMOUNT_WISH_ITEM",
    itemId: item.id,
    amount
  }
}

export function addCartItem(item, amount) {
  return function (dispatch, getState) {
    let state = getState()
    if(state.cart.items[item.id]){
      dispatch({
        type: "SET_AMOUNT_CART_ITEM",
        itemId:item.id,
        amount : state.cart.items[item.id].amount + amount
      })
    } else {
      item.amount = amount ? amount : 1;
      dispatch({
        type: "ADD_CART_ITEM",
        item
      })
    }
  }
}

export function fetchCart(user) {

  return function (dispatch) {
    if(user){
      console.log("refreshing cart for ", user.id)
      return axios( api + '/cart/' + user.id).then(res=>{
        if(res.data.cart){
          console.log("got cart", res.data.cart)
          dispatch(setCart(res.data.cart))
        }
        if(res.data.wish){
          console.log("got wish", res.data.cart)
          dispatch(setWish(res.data.wish))
        }
      })
    } else {
      return null
    }

    
  }
}