import React, { useEffect } from 'react'
import ReactPlayer from 'react-player'
import { connect } from 'react-redux'

import { setPlayerTrackIndex, setPlayerRelease } from '../redux/actions/releases'

import Slider from 'react-rangeslider'

// To include the default styles of range slider
import 'react-rangeslider/lib/index.css'

import ContentLoader from "react-content-loader"

const Player = (props) => {

  const player = React.useRef()
  const [playing, setPlaying] = React.useState(true)
  const [seeking, setSeeking] = React.useState(false)
  const [played, setPlayed] = React.useState(0)
  const { release, releaseIndex, trackIndex, playingStore } = props

  useEffect(()=>{
    setPlaying(playingStore)
  }, [playingStore])


  const handlePlayClick = () => {
    setPlaying(!playing)
  }

  const handleNextClick = () => {
    handleOnEnded()
  }

  const handlePrevClick = () => {
    // same release
    if (trackIndex > 0) {
      props.dispatch(setPlayerTrackIndex(trackIndex - 1))
      setPlaying(true)
    } else {
      // handle prev release selection
      if (releaseIndex > 0) {
        props.dispatch(setPlayerRelease(props.catalog[releaseIndex - 1], releaseIndex - 1, props.catalog[releaseIndex - 1].snippets.length - 1))
        setPlaying(true)
      } else {
        //get last release
        props.dispatch(setPlayerRelease(props.catalog[props.catalog.length - 1], releaseIndex + 1, 0))
        setPlaying(true)
      }
    }
  }


  const handleSeekMouseDown = e => {
    setSeeking(true)
  }

  const handleSeekChange = e => {
    // setPlayed(parseFloat(e.target.value))
    // console.log(e)
    setPlayed(parseFloat(e))
    if (player.current) {
      player.current.seekTo(parseFloat(e))
    }
  }

  const handleSeekMouseUp = e => {
    // console.log(e)
    setSeeking(false)
    // if(player.current){
    //   player.current.seekTo(parseFloat(e.target.value))
    // }
  }

  const handleProgress = state => {
    // console.log('onProgress', state)
    if (!seeking) {
      setPlayed(state.played)
    }
  }


  const handleOnEnded = () => {
    // get next track of current release
    let tracklength = release.snippets.length - 1
    if (tracklength > trackIndex) {
      props.dispatch(setPlayerTrackIndex(trackIndex + 1))
      setPlaying(true)
    } else {
      // get next release
      props.dispatch(setPlayerRelease(props.catalog[releaseIndex + 1], releaseIndex + 1, 0))
    }
  }


  return (
    <div className="container xplayer">

      <div className="slider-horizontal mobile-slider">
        <Slider value={played}
          min={0}
          step={0.0000000000001}
          max={0.999999}
          tooltip={false}
          orientation={"horizontal"}
          onChangeStart={handleSeekMouseDown}
          onChange={handleSeekChange}
          onChangeComplete={handleSeekMouseUp} />
      </div>

      <div className="is-hidden">
        {release && <ReactPlayer ref={player} onProgress={handleProgress} onEnded={handleOnEnded} config={{ playing: true, file: { forceAudio: true } }} url={'https://couch.inchbyinch.eu/catalog/' + release.id + '/' + release.snippets[trackIndex].filename} playing={playing} />}
      </div>

      <figure className="image is-256x256 cover-loader-mobile" >
        {release && (
          <>
            {release.coverFront && <img src={'https://couch.inchbyinch.eu/catalog/' + release?._id + '/' + release.coverFront} alt={release.text}></img>}


          </>



        )}
        {!release && (
          <CoverLoader />
        )}

      </figure>



      <div className="slider-horizontal slider-desktop">
        <Slider value={played}
          min={0}
          step={0.0000000000001}
          max={0.999999}
          tooltip={false}
          orientation={"horizontal"}
          onChangeStart={handleSeekMouseDown}
          onChange={handleSeekChange}
          onChangeComplete={handleSeekMouseUp} />
      </div>

      <div className="button-row">
        <button className="button is-white mx-1" onClick={handlePrevClick}>
          <span className="icon is-small">
            <i className="fas fa-step-backward"></i>
          </span>
        </button>
        <button className="button is-white mx-1" onClick={handlePlayClick}>
          {playing && (
            <span className="icon is-small">
              <i className="fas fa-pause"></i>
            </span>
          )}
          {!playing && (
            <span className="icon is-small">
              <i className="fas fa-play"></i>
            </span>
          )}
        </button>
        <button className="button is-white mx-1" onClick={handleNextClick}>
          <span className="icon is-small">
            <i className="fas fa-step-forward"></i>
          </span>
        </button>
      </div>

      {release && (
        <>
          <div className="is-size-6 player-details has-text-weight-bold">{release.name}</div>
          <div className="is-size-7 player-details">{release.snippets[trackIndex].artist} - {release.snippets[trackIndex].title}</div>
        </>
      )}

    </div>
  )
}

const mapStateToProps = (state) => ({
  catalog: state.releases.catalog,
  playingStore: state.releases.playing,
  release: state.releases.player,
  releaseIndex: state.releases.playerReleaseIndex,
  trackIndex: state.releases.playerTrackIndex
})

export default connect(mapStateToProps)(Player)



const CoverLoader = (props) => (
  <ContentLoader
    speed={2}
    // width={100}
    // height={100}
    viewBox="0 0 100 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="100" height="100" />
  </ContentLoader>
)