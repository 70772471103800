import React from 'react'
import logo from '../logo.svg';
import Menu from './Menu'
import AuthBar from './AuthBar';
import { connect } from 'react-redux';
import { filterBySearch } from '../redux/actions/filter';
import { useHistory } from 'react-router-dom';

const Navbar = (props) => {

  const history = useHistory()
  const [active, setActive] = React.useState(false)
  const [search, setSearch] = React.useState("")

  const handleBurger = (e) => {
    console.log("menu close")
    e.preventDefault()
    setActive(old => !old)
  }
  
  const handleClose = () => {
    setActive(old => !old)

  }

  const handleSearch = () => {
    props.dispatch(filterBySearch(search))
    history.push('/releases')
  }

  const onKeyPress = (e) => {
    if(e.keyCode == 13){
      props.dispatch(filterBySearch(search))
      history.push('/releases')
    }
 }

  return (
    <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="container is-fluid">
        <div className="navbar-brand">
          <a className="navbar-item mgl-large" href="https://inchbyinch.eu">
            <img src={logo} alt="inchbyinch" height={36} />
          </a>
          <a onClick={handleBurger} href="#menu" role="button" className={active ? "navbar-burger is-active" : "navbar-burger"} data-target="navMenu" aria-label="menu" aria-expanded="false">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={active ? "navbar-menu is-active" : "navbar-menu"}>
          <div className="navbar-end">
            <div className="navbar-item">
              <AuthBar />
            </div>

            <div className="navbar-item">
              <div className="field has-addons">
                <div className="control">
                  <input className="input" type="text" placeholder="Search" value={search} onKeyDown={onKeyPress} onChange={(e) => setSearch(e.target.value)} />
                </div>
                <div className="control">
                  <button className="button has-background-light" onClick={handleSearch}>
                    <span className="icon is-small">
                      <i className="fas fa-search"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="is-hidden-tablet mx-2">
              <Menu onClose={handleClose} />

            </div>
          </div>

        </div>
      </div>
    </nav>
  )
}

export default connect()(Navbar)