import React from 'react'
import { connect } from 'react-redux'



const Price = props => {
  let price = ""
  let unit = ""

  let rp = 0
  if(!props.type){
    price = ""
  } else if(props.type === "shop"){
      price = parseFloat(props.priceNet).toFixed(2)
      rp = parseFloat(props.priceNet).toFixed(2)
      unit = " € + VAT"
  } else if(props.type === "privat"){
    price = (parseFloat(props.priceNet) *1.33).toFixed(2)
    rp = (parseFloat(props.priceNet)*1.33).toFixed(2)
    unit = " € +  VAT"
  } else {
    // artist
    price = ""
    unit = ""
  }


  if(props.amount){
    price = (rp * props.amount).toFixed(2)
  }
  
  if(props.type === "artist") return null
  if(!props.type) return null

  return (
    <div className="is-size-6">{price} {props.nv && unit} {!props.nv && "€"}</div>
  )


}

const mapStateToProps = state => ({
  type: state.auth.user.type
})
export default connect(mapStateToProps)(Price)