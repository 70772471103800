import React from 'react'
import axios from 'axios'
import {api} from '../api'

const Contact = (props) => {
  const contactForm = React.useRef()
  const [message, setMessage] = React.useState("")

  const handleSend = async () => {
    var formData = new FormData(contactForm.current);
    let ok = await axios.post(api + '/contact', formData);
    setMessage(ok.data.message)
  }

  return (
    <>
          <h1 className="is-size-2 has-text-weight-bold">Contact</h1><br />
          <form ref={contactForm} onSubmit={(e) => e.preventDefault()} className=" mw-400">
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input className="input" type="text" name="name" placeholder="Your Name" />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input className="input" type="email" name="mail" placeholder="Your Email" />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <textarea className="textarea" type="text" name="message" placeholder="Your Message" />
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-primary" onClick={handleSend}>
                  Send
            </button>
              </p>
            </div>
            <p>{message}</p>
          </form>
   
    </>
  )
}

export default Contact