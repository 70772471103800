import axios from 'axios'
import {api} from '../../api'

export function setLogin(user) {
  return {
    type: "LOGIN",
    user
  }
}
export function setOrders(orders) {
  return {
    type: "SET_ORDERS",
    orders
  }
}

export function logout() {
  return {
    type: "LOGOUT"
  }
}

export function setAuthMessage(message) {
  return {
    type: "SET_AUTH_MESSAGE",
    message
  }
}

export function setLoading(loading) {
  return {
    type: "SET_LOADING",
    loading
  }
}


export function refreshLogin(){
  return function (dispatch,getState){
    let state = getState()
    if(state.auth.user){
      // console.log("refreshing auth: ", state.auth.user.mainEmail, state.auth.user.token)
      let formdata = new FormData()
      formdata.append('mail', state.auth.user.mainEmail)
      formdata.append('token', state.auth.user.token)
      // return dispatch(login(formdata))
      return axios({
        method: 'post',
        url: api + '/refresh',
        data: formdata,
        headers: {'Content-Type': 'multipart/form-data' }
    }).then(res=>{
      // console.log(res.data)
      if (res.data.success === false) {
        console.log("AUTH REFRESH FAILED")
        // dispatch(setAuthMessage("Password or Mail incorrect, please try again!"))
      }else {
        console.log(res.data.user)
        dispatch(setLogin(res.data.user))
      }
    })


    } else {
      return null;
    }

  }
}


export function login(body) {

  return function (dispatch) {
    console.log("LOGIN")
    dispatch(setLoading(true))
    return axios({
        method: 'post',
        url: api + '/login',
        data: body,
        headers: {'Content-Type': 'multipart/form-data' }
    }).then(res=>{
      // console.log(res.data)
      dispatch(setLoading(false))
      if (res.data.success === false) {
        dispatch(setAuthMessage("Password or Mail incorrect, please try again!"))
      }else {
        console.log(res.data.user)
        dispatch(setLogin(res.data.user))
      }
    })

    
  }
}

export function getOrders(uid) {

  return function (dispatch) {
    console.log("GET ORDERS")
    return axios({
        method: 'get',
        url: api + '/orders/' + uid
    }).then(res=>{
      // console.log(res.data)
      if (res.data.success === false) {
        dispatch(setOrders([]))
      }else {
        dispatch(setOrders(res.data.orders))
      }
    })   
  }
}