
const initialState = {catalog: [], genres: [], labels:[], player: false, playerReleaseIndex: false, filter:false, playing: false}

function releases(state = initialState, action) {
  switch (action.type) {
    case "SET_CATALOG":
      return Object.assign({}, state, {
        catalog: action.catalog
      })
    case "SET_COMPLETE_CATALOG":
      return Object.assign({}, state, {
        completeCatalog: action.catalog
      })
    case "SET_GENRES":
      return Object.assign({}, state, {
        genres: action.genres
      })
    case "SET_LABELS":
      return Object.assign({}, state, {
        labels: action.labels
      })
    case "SET_PLAYER":
      return Object.assign({}, state, {
        playing: action.playing,
        player: action.release,
        playerReleaseIndex: action.releaseIndex,
        playerTrackIndex: action.trackIndex
      })
    case "SET_PLAYER_TRACK_INDEX":
      return Object.assign({}, state, {
        playerTrackIndex: action.trackIndex
      })
    case "SET_FILTER":
      return Object.assign({}, state, {
        filter: action.filter
      })

    default:
      return state
  }
}


export default releases