import React from 'react'
import Stock from './Stock'
import { connect } from 'react-redux'
import { setPlayerRelease } from '../redux/actions/releases'

import { Link } from 'react-router-dom'
import Price from './Price'
import ReleaseActions from './ReleaseActions'
import { useInView } from 'react-intersection-observer'
import { filterByLabel } from '../redux/actions/filter'



const Release = (props) => {

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  })


  const handleTrackClick = (e) => {
    console.log(e.target.dataset.index)
    let trackIndex = parseInt(e.target.dataset.index);
    let releaseIndex = props.index
    let release = props.release
    props.dispatch(setPlayerRelease(release, releaseIndex, trackIndex))
  }

  const handleFilterByLabel = (e) => {
    let l = e.target.dataset.label
    props.dispatch(filterByLabel(l))
  }


  const getLabel = (slug) => {
    let ln = props.labels.filter(l => {
      return l.slug === slug
    })
    if (ln[0]) {
      return ln[0].name
    }
    else {
      return slug
    }
  }
  // console.log(props.release)

  return (
    <div ref={ref} className="release-min">
      {inView && (
        <div className={props.noborder ? "columns release mb-5 fadein" : "columns release release-border mb-5 fadein"}>
          <div className="column is-narrow">
            <figure className="image  is-256x256">
              <img src={'https://couch.inchbyinch.eu/catalog/' + props.release._id + '/' + props.release.coverFront} alt={props.release.text}></img>
            </figure>
          </div>
          <div className="column is-two-fifth release-center">
            <div>
              <div className="flex-head">
                <Link to={"/release/" + props.release._id}>
                  <h3 className={props.noborder ? "is-size-5 is-unselectable has-text-weight-bold" : "is-size-6 is-unselectable has-text-weight-bold"}>{props.release.name}</h3>
                </Link>
                <div className="tags">
                  {props.release.staffPick && <span className="tag is-primary">Staff Pick</span>}
                  <span className="tag has-background-grey-light">{props.release.format}</span>
                </div>
              </div>
              <Link to={"/release/" + props.release.id}>
                <h3 className="is-size-5 is-unselectable">{props.release.text}</h3>
              </Link>
              <h3 className="is-size-6 is-unselectable is-pointer" data-label={props.release.label} onClick={handleFilterByLabel}>{getLabel(props.release.label)}</h3>
            </div>
            <div>


              <div className="flex-head">
                <Price priceGross={props.release.priceGross} priceNet={props.release.priceNet} nv={true} />
                <Stock stock={props.release.stock} presale={props.release.preorder} releaseDate={props.release.releaseDate} />

              </div>
              <ReleaseActions release={props.release} promo={props.promo} />
            </div>
          </div>
          <div className="column is-two-fifth">
            {props.release.snippets && props.release.snippets.map((track, index) => {
              console.log(track)
              return (
                <div style={{fontFamily: "Inter"}} className={props.playerReleaseIndex === props.index ? props.playerTrackIndex === index ? "track is-active is-unselectable	" : "track is-unselectable	" : "track is-unselectable	"} key={track.title + track.track} data-index={index} onClick={handleTrackClick} >
                  <div className="track-spur" data-index={index} onClick={handleTrackClick}>
                    {track.track}
                  </div>
                  {track.artist} - {track.title}
                </div>
              )
            })}
          </div>



        </div>


      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  playerReleaseIndex: state.releases.playerReleaseIndex,
  playerTrackIndex: state.releases.playerTrackIndex,
  labels: state.releases.labels
})

export default connect(mapStateToProps)(Release)