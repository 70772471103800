import React from 'react'
import { connect } from 'react-redux'

import WishItem from '../components/WishItem'
const Wishlist = (props) => {



  return (
    <div>
      <h2 className="is-size-3 has-text-weight-bold mb-3">Your Wishlist</h2>
      <p className="my-3">Items on your Wishlisht which are not released yet will be moved to your cart automatically when they come available!</p>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th></th>
            <th>Cover</th>
            <th>Catalog #</th>
            <th>Title</th>
            <th>Release Date</th>
            <th>Amount</th>
            <th>Price</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(props.wish).map((item => (
            <tr key={item.id}>
              <WishItem  release={item} />
            </tr>
          )))}
        </tbody>
      </table>

            
    </div>
  )
}


const mapStateToProps = state => ({
  wish: state.cart.wish,
  type: state.auth.user.type,
  uid: state.auth.user.id
})


export default connect(mapStateToProps)(Wishlist)