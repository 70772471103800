import React from 'react'


// https://www.instagram.com/inchbyinch.recordstore/?__a=1


const Instagram = () => {

  return (
    <div className="instagramx">
      <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      <div className="elfsight-app-8a8e489f-d99f-44d7-b19b-27beb65b5ac5"></div>
    </div>
  )
}

export default Instagram
