export function filterByGenre(genre) {
  return function (dispatch, getState) {
    let state = getState()
    let c = state.releases.completeCatalog

    if(genre === "All"){
      return dispatch(
        {
          type: "SET_CATALOG",
          catalog:c
        }
      ) 
    }
    if(genre === "Genre"){
      return dispatch(
        {
          type: "SET_CATALOG",
          catalog:c
        }
      ) 
    }

    let catalog = []
    c.forEach(r=>{
      let t = false
      if(r.genre === genre){
        t = true
      }
      if(r.genre2 === genre){
        t = true
      }
      if(r.genre3 === genre){
        t = true
      }

      if(t){
        catalog.push(r)
      }
    })
    dispatch(setFilter(true))
    return dispatch(
      {
        type: "SET_CATALOG",
        catalog
      }
    ) 
  }
}


export function filterByMonth(month) {
  return function (dispatch, getState) {
    let state = getState()
    let c = state.releases.completeCatalog

    if(month === "All"){
      return dispatch(
        {
          type: "SET_CATALOG",
          catalog:c
        }
      ) 
    }

    let catalog = []
    c.forEach(r=>{
      let m = r.releaseDate.split('-')[1]
      let y = r.releaseDate.split('-')[0]
      if(m === month && y === new Date().getFullYear().toString()){
        catalog.push(r)
      }
    })
    dispatch(setFilter(true))
    return dispatch(
      {
        type: "SET_CATALOG",
        catalog
      }
    ) 
  }
}



export function filterByLabel(label) {
  return function (dispatch, getState) {
    let state = getState()
    let c = state.releases.completeCatalog

    let catalog = []
    c.forEach(r=>{
      if(r.label === label){
        catalog.push(r)
      }
    })
    dispatch(setFilter(true))
    return dispatch(
      {
        type: "SET_CATALOG",
        catalog
      }
    ) 
  }
}


export function filterBySearch(search) {
  return function (dispatch, getState) {
    let state = getState()
    let c = state.releases.completeCatalog

    let catalog = []
    c.forEach(r=>{
      let rc = JSON.stringify(r).toLowerCase()
      let st = search.toLowerCase()

      if(rc.includes(st)){
        catalog.push(r)
      }
    })
    dispatch(setFilter(true))
    return dispatch(
      {
        type: "SET_CATALOG",
        catalog
      }
    ) 
  }
}



export function setFilter(filter){
  return {
    type: "SET_FILTER",
    filter
  }
}


export function resetFilter(){
  return function(dispatch, getState){
    let state = getState()
    let c = state.releases.completeCatalog
    dispatch(setFilter(false))
    return dispatch(
      {
        type: "SET_CATALOG",
        catalog: c
      }
    ) 
  }
}