import React from 'react'
import { connect } from 'react-redux'
//import PDF from '../components/Pdf'

const Imprint = (props) => {

  const [state, setState] = React.useState(1)

  return (
    <>
      <div>
        <h1 className="is-size-2 has-text-weight-bold">Imprint</h1>
        <div>
          <span className='link' style={{
            cursor: "pointer"}} onClick={() => setState(1)}>
            Imprint
          </span> &nbsp;|&nbsp;
          <span className='link'  style={{ cursor: "pointer" }} onClick={() => setState(2)}>
            AGB
          </span>&nbsp;|&nbsp;
          <span className='link'  style={{ cursor: "pointer" }} onClick={() => setState(3)}>
            GTC
          </span><br /><br />

        </div>
        {state == 1 && (
          <iframe src="https://inchbyinch-imprint.vercel.app/imprint.html" style={{ width: "100%", height: "80vh", border: "0px" }} />
        )}
        {state == 2 && (
          <iframe src="https://inchbyinch-imprint.vercel.app/agb.html" style={{ width: "100%", height: "80vh", border: "0px" }} />
        )}
        {state == 3 && (
          <iframe src="https://inchbyinch-imprint.vercel.app/gtc.html" style={{ width: "100%", height: "80vh", border: "0px" }} />
        )}

      </div>
    </>
  )
}


const mapStateToProps = state => ({
  cms: state.cms.home
})


export default connect(mapStateToProps)(Imprint)