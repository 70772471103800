import React from 'react'
import { connect } from 'react-redux'
import Release from '../components/Release'
import ReleaseFilter from '../components/ReleaseFilter'
import ReleaseLoader from '../components/ReleaseLoader'


const Releases = (props) => {

  return (
    <div className="columns no-h-scroll">
      <div className="column is-10">

        {!props.catalog.length > 0 && [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((item) => {
          return (<ReleaseLoader key={item} />)
        })}
        <div className="mt-3"></div>
        {props.catalog.length > 0 && props.catalog.map((release, index) => {
          return (<Release key={release._id} release={release} index={index} />)
        })}



      </div>
      <div className="column is-2 pt-0 sticky">
        <ReleaseFilter />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  catalog: state.releases.catalog
})

export default connect(mapStateToProps)(Releases)