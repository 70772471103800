import React from 'react'
import { connect } from 'react-redux'
import { addCartItem, addWishItem } from '../redux/actions/cart'
import Tooltip from '@material-ui/core/Tooltip';
// import download from 'downloadjs'

const ReleaseActions = (props) => {

  const amountRef = React.useRef()
  const wishAmountRef = React.useRef()

  const handleAddToCart = () => {

    let amount = parseInt(amountRef.current.value)
    props.dispatch(addCartItem(props.release, amount))
  }

  const handleAddToWish = () => {
    // props.dispatch(addCartItem(props.release))
    let amount = parseInt(wishAmountRef.current.value)
    props.dispatch(addWishItem(props.release, amount))
  }

  const handleInfoDownload = () => {
    // console.log('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/infoSheet.pdf")
    // download('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/infoSheet.pdf", props.release.name + "_infosheet.pdf")
    // /download/:id/:releasename/:type
    window.open('https://api.inchbyinch.eu/download/' + props.release.id + "/" + props.release.name  + "/infoSheet.pdf", '_blank');
  }

  const handleShopKitDownload = () => {
    // console.log('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/shopkit.zip")
    // download('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/shopkit.zip", props.release.name +"_shopkit.zip")
    // window.open('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/shopkit.zip", '_blank');
    window.open('https://api.inchbyinch.eu/download/' + props.release.id + "/" + props.release.name  + "/shopkit.zip", '_blank');
  }

  const handleAudioDownload = () => {
    // console.log('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/fullaudio.zip")
    // download('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/fullaudio.zip",  props.release.name +"_fullaudio.zip")
    // // window.open('https://couch.inchbyinch.eu/catalog/' + props.release.id + "/fullaudio.zip", '_blank');
    window.open('https://api.inchbyinch.eu/download/' + props.release.id + "/" + props.release.name  + "/fullaudio.zip", '_blank');
  }



  if (props.authenticated) {
    return (
      <div className="flex-head">
        <div className="mt-2">
          {(!props.release.preorder === true && (props.type === "shop" || props.type === "privat") && props.release.stock > 0) && (
            <div className="field has-addons">
              <div className="control">
                <input ref={amountRef} className="input amount-input" type="number" defaultValue={1} min={1} max={props.release.stock} placeholder="Amount" />
              </div>
              <div className="control">
                <a className="button is-primary" onClick={handleAddToCart}>
                  <span className="icon is-right">
                    <i className="fas fa-shopping-cart"></i>
                  </span>
                  <span>
                  Add to Cart
                  </span>
                </a>
              </div>
            </div>
          )}



          {(props.release.preorder === true && (props.type === "shop" || props.type === "privat")) && (
            <div className="field has-addons">
            <div className="control">
              <input ref={wishAmountRef} className="input amount-input" type="number" defaultValue={1} placeholder="Amount" />
            </div>
            <div className="control">
              <a className="button is-primary" onClick={handleAddToWish}>
                <span className="icon is-right">
                  <i className="fas fa-star"></i>
                </span>
                <span>
                Add to Wishlist
                </span>
              </a>
            </div>
          </div>
          )}

        </div>
        <div className="mt-2">
          {(!props.release.preorder === true && (props.type === "shop" || props.type === "privat")) && (
            <Tooltip title="Add to Wishlist" aria-label="add" placement="top" arrow>
              <button className="button is-info mr-1" onClick={handleAddToWish}>
                <span className="icon">
                  <i className="fas fa-star"></i>
                </span>
              </button>
            </Tooltip>
          )}
          {(props.release._attachments["infoSheet.pdf"] && (props.type === "shop" || props.type === "artist")) && (
            <Tooltip title="Download Info Sheet" aria-label="add" placement="top" arrow>
              <button className="button is-info mr-1" onClick={handleInfoDownload}>
                <span className="icon">
                  <i className="fas fa-file-alt"></i>
                </span>
              </button>
            </Tooltip>
          )}

          {(props.type === "shop" && props.release._attachments["shopkit.zip"]) && (
            <Tooltip title="Download Shopkit" aria-label="add" placement="top" arrow>
              {/* <a href={'https://couch.inchbyinch.eu/catalog/' + props.release.id + "/shopkit.zip"} download={props.release.id + "_shopkit.zip"}> */}
                <button className="button is-info mr-1" onClick={handleShopKitDownload}>
                  <span className="icon">
                    <i className="fas fa-tags"></i>
                  </span>
                </button>
              {/* </a> */}
            </Tooltip>
          )}

          {(!props.release.nopromo === true && (props.type === "shop" || props.type === "artist") && props.release._attachments["fullaudio.zip"]) && (
            <Tooltip title="Download Mp3's" aria-label="add" placement="top" arrow>

              <button className="button is-info mr-0" onClick={handleAudioDownload}>
                <span className="icon">
                  <i className="fas fa-file-audio"></i>
                </span>
              </button>
            </Tooltip>
          )}
        </div>

      </div>
    )
  } else if(props.promo) {
    return (
      <>
      {(!props.release.nopromo === true && props.release._attachments["fullaudio.zip"]) && (
        <Tooltip title="Download Mp3's" aria-label="add" placement="top" arrow>
          <button className="button is-info mr-0" onClick={handleAudioDownload}>
            <span className="icon">
              <i className="fas fa-file-audio"></i>
            </span>
          </button>
        </Tooltip>
      )}
      </>
    )
  } else {
    return null;
  }
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  type: state.auth.user.type
})

export default connect(mapStateToProps)(ReleaseActions)