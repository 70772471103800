import React from 'react'
import Instagram from '../components/Instagram'
import ShopMap from '../components/ShopMap'

const Location = () => {
  return (
    <>
    <h2 className="is-size-2 has-text-weight-bold">Location</h2><br/>
    <div className="columns">
      <div className="column is-one-third is-half-tablet">
        <Instagram />
        <br />
        <br />
        <a className="link" href="https://www.discogs.com/de/seller/Inch-By-Inch/profile" target="_blank" rel="noopener noreferrer">
          <button className="button is-dark">
            <span className="icon">
              <i className="fa fa-caret-right"></i>
            </span>
            <span>We are on Discogs!</span>
          </button>
        </a>
      </div>
      <div className="column is-two-third is-half-tablet">
        <ShopMap />
      </div>
    </div>
    </>
  )
}

export default Location