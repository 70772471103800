
import axios from 'axios'
import {api} from '../../api'
// Catalog

function setCatalog(catalog) {
  return {
    type: "SET_CATALOG",
    catalog
  }
}

function setCompleteCatalog(catalog) {
  return {
    type: "SET_COMPLETE_CATALOG",
    catalog
  }
}




export function fetchCatalog() {

  return function (dispatch) {
    return axios(api + '/catalog/latest')
      .then(res => {
        
        dispatch(setCatalog(res.data))
        dispatch(setCompleteCatalog(res.data))
      })
  }
}

//  Genre

function setGenres(genres) {
  return {
    type: "SET_GENRES",
    genres
  }
}


export function fetchGenres() {

  return function (dispatch) {
    return axios(api + '/genre')
      .then(res => {
        // console.log(res.data.genres)
        dispatch(setGenres(res.data.genres))
      })
  }
}

//  Label

function setLabels(labels) {
  return {
    type: "SET_LABELS",
    labels
  }
}


export function fetchLabels() {

  return function (dispatch) {
    var t0 = performance.now()
    return axios(api + '/label')
      .then(res => {
        var t1 = performance.now()
        console.log("API time:   " + (t1 - t0) + " milliseconds.")
        let lbls = []
        res.data.forEach(l=>{
          lbls.push(l.doc)
        })
        dispatch(setLabels(lbls))
      })
  }
}


// Player

export function setPlayerRelease(release, releaseIndex, trackIndex) {
  return {
    type: "SET_PLAYER",
    playing: true,
    release,
    releaseIndex,
    trackIndex
  }
}

export function setPlayerReleaseInit(release, releaseIndex, trackIndex) {
  return {
    type: "SET_PLAYER",
    playing: false,
    release,
    releaseIndex,
    trackIndex
  }
}

export function setPlayerTrackIndex(trackIndex) {
  return {
    type: "SET_PLAYER_TRACK_INDEX",
    trackIndex
  }
}


//  Filter



