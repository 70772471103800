import { combineReducers } from 'redux'
import releases from './releases'
import auth from './auth'
import cart from './cart'
import cms from './cms'

const rootReducer = combineReducers({
  auth,
  releases,
  cart,
  cms
})

export default rootReducer