import React from 'react'
import { connect } from 'react-redux'
import ReactStars from 'react-stars'
import axios from 'axios'
import { api } from '../api'
import { setLoading } from '../redux/actions/auth'

const Feedback = props => {

  const [rating, setRating] = React.useState(0)
  const [message, setMessage] = React.useState("")
  const contactForm = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false)

  const ratingChanged = (r) => {
    console.log(r)
    setRating(r)
  }

  const handleSend = async () => {
    setIsLoading(true)
    var formData = new FormData(contactForm.current);
    formData.append('rating', rating)
    formData.append('releaseId', props.release.id)
    formData.append('releaseName', props.release.name)
    if (props.user) {
      formData.append('userId', props.user.id)
    }
    let ok = await axios.post(api + '/feedback', formData);
    setMessage(ok.data.message)
    setIsLoading(false)
  }


  return (
    <div>
      <h3 className="is-size-5 has-text-weight-bold">Feedback</h3>
      <div className="is-inline-flex flex-center">
        <span className="has-text-weight-bold">
          Your Rating: &nbsp;
        </span>
        <ReactStars
          count={5}
          value={rating}
          onChange={ratingChanged}
          size={28}
          color1={"#d3d4d8"}
          color2={'#242c3e'} />

      </div>
      <br />
      <form ref={contactForm} onSubmit={(e) => e.preventDefault()} className=" mw-400">
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input className="input" type="text" name="name" defaultValue={props.user.name ? props.user.name : ""} placeholder="Your Name" />
            <span className="icon is-small is-left">
              <i className="fas fa-user"></i>
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control has-icons-left has-icons-right">
            <input className="input" type="email" name="mail" defaultValue={props.user.mainEmail ? props.user.mainEmail : ""} placeholder="Your Email" />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
          </p>
        </div>
        <div className="field">
          <p className="control">
            <textarea className="textarea" type="text" name="message" placeholder="Your Message (optional)" />
          </p>
        </div>
        <div className="field">
          <p className="control">
            <button className="button is-primary" disabled={isLoading} onClick={handleSend}>
              {isLoading ? <Spinner/> : "Send"}
            </button>
          </p>
        </div>
        <p>{message}</p>
      </form>

    </div>
  )


}

const mapStateToProps = state => ({
  user: state.auth.user
})
export default connect(mapStateToProps)(Feedback)




const Spinner = () => {
  return (
    <svg width={64} height={30} viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">
      <circle cx={15} cy={15} r={15}>
        <animate attributeName="r" from={15} to={15} begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from={1} to={1} begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
      </circle>
      <circle cx={60} cy={15} r={9} fillOpacity="0.3">
        <animate attributeName="r" from={9} to={9} begin="0s" dur="0.8s" values="9;15;9" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from="0.5" to="0.5" begin="0s" dur="0.8s" values=".5;1;.5" calcMode="linear" repeatCount="indefinite" />
      </circle>
      <circle cx={105} cy={15} r={15}>
        <animate attributeName="r" from={15} to={15} begin="0s" dur="0.8s" values="15;9;15" calcMode="linear" repeatCount="indefinite" />
        <animate attributeName="fill-opacity" from={1} to={1} begin="0s" dur="0.8s" values="1;.5;1" calcMode="linear" repeatCount="indefinite" />
      </circle>
    </svg>
  )
}