import React from 'react'
import { connect } from 'react-redux'
import { api } from '../api'
import { getOrders } from '../redux/actions/auth'
import {format} from 'date-fns'
import { Link } from 'react-router-dom'

const User = (props) => {
  if (props.user) {
    if (!props.orders) {
      props.dispatch(getOrders(props.user.id))
    }

    return (
      <>
        {/* <h1 className="is-size-2">Your Account</h1> */}
        <div className="mt-4">
          <h4 className="is-size-3 has-text-weight-bold">Your Details</h4>
          <div className="columns">
            <div className="column">
              <h4 className="is-size-5 ">Contact</h4><br/>
                {props.user.mainEmail}<br/>
              {props.user.type === "shop" && (
                <>
                {props.user.name} <br />
                {props.user.name2} <br />
                {props.user.vatNumber} <br />
                </>
              )}
              {props.user.type !== "shop" && (
                <>
                {props.user.surename} {props.user.familyname} <br />
                </>
              )}
            </div>
            {(props.user.type !== "artist" && props.user.mainAddress) && (
              <div className="column">
                <h4 className="is-size-5 ">Shipping Address</h4> <br />
                {props.user.mainEmail} <br />
                {props.user.mainAddress.street} <br />
                {props.user.mainAddress.zip} <br />
                {props.user.mainAddress.city} <br />
              </div>
            )}
            {(props.user.type !== "artist" && props.user.billingAddress) && (
              <div className="column">
                <h4 className="is-size-5">Billing Address</h4><br/>
                {props.user.billingEmail ? props.user.billingEmail : props.user.mainEmail} <br />
                {props.user.billingAddress.street} <br />
                {props.user.billingAddress.zip} <br />
                {props.user.billingAddress.city} <br />
              </div>
            )}
          </div>
          <Link to="/user/edit">
          <button className="button is-info">Edit Your Details</button>
          </Link>

        </div>
        <hr></hr>
        <div className="mt-5">
          <h4 className="is-size-3 has-text-weight-bold">Your Orders</h4>
          <table>
            <thead>
              <tr>
                <th className="pr-3 py-3 is-size-5">Order</th>
                <th className="pr-3 py-3 is-size-5">Date</th>
                <th className="px-3 py-3 is-size-5">Delivery Note</th>
                <th className="px-3 py-3 is-size-5">Invoice</th>
                <th className="px-3 py-3 is-size-5">Tracking Link</th>
              </tr>
            </thead>
            <tbody>
              {!props.orders && <span>No orders to display.</span>}
              {props.orders && props.orders.map(order => (
                <tr className="pb-3">
                  <td className="pr-3 py-3">{order.orderId}</td>
                  <td className="pr-3 py-3">{order.date && format(new Date(order.date), 'MM/dd/yyyy')}</td>
                  <td className="px-3 py-3 is-info">
                    {order.deliveryNoteId && (
                    <a className="link tag is-info" target="_blank" rel="noopener noreferrer" href={api + '/document/deliverynote/' + order.deliveryNoteId}>
                      <span class="icon is-small">
                        <i class="fas fa-download"></i>
                      </span>
                      <span className="is-family-monospace">
                      {order.deliveryNoteName}
                      </span>
                     </a>
                    )}
                  </td>
                  <td className="px-3 py-3">
                    {order.invoiceId && (
                      <a className="link tag is-info" target="_blank" rel="noopener noreferrer" href={api + '/document/invoice/' + order.invoiceId}>
                        <span class="icon is-small">
                          <i class="fas fa-download"></i>
                        </span>
                        <span className="is-family-monospace">
                        {order.invoiceName}
                        </span>
                      </a>
                    )}
                    {!order.invoiceId && (
                      <span>We are processing your invoice.</span>
                    )}
                  </td>
                  <td className="px-3 py-3">
                    {order.trackingLink && (
                      <a href={order.trackingLink} target="_blank" rel="noopener noreferrer">{order.trackingLink.substring(8, 30)}...</a>
                    )}
                    {!order.trackingLink && (
                      <span>Tracking not available yet.</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>



        </div>


      </>
    )
  } else {
    return (
      <div>Please Login</div>
    )
  }
}



const mapStateToProps = (state) => ({
  user: state.auth.user,
  orders: state.auth.orders
})

export default connect(mapStateToProps)(User)