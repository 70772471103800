import React from 'react'
import Price from './Price'
import {addCartItem, deleteWishItem, setAmountWishItem} from '../redux/actions/cart'
import { connect } from 'react-redux'


const WishItem = (props) => {

  
  const [amount, setAmount] = React.useState(props.release.amount);
  
  const handleRemove = () => {
    props.dispatch(deleteWishItem(props.release))
  }



  const handleAmountInput = (e) => {
    let v = e.target.value;
    setAmount(parseInt(v))
  }

  const updateAmount = () => {
    props.dispatch(setAmountWishItem(props.release, amount))
  }


  return (
    <>
      <td>
        <button className="button" onClick={handleRemove}>
        <span className="icon is-right">
                <i className="fas fa-trash"></i>
              </span>
        </button>
      </td>
      <th>
        <figure className="image  is-48x48">
          <img src={'https://couch.inchbyinch.eu/catalog/' + props.release.id + '/' + props.release.coverFront} alt={props.release.text}></img>
        </figure>
      </th>
      <td>
        {props.release.name}
      </td>
      <td>
        {props.release.text}
      </td>
      <td>
        {props.release.releaseDate}
      </td>
      <td className="has-text-right">
        <div className="field has-addons">
          <div className="control">
            <input className="input amount-input" type="number" value={amount} onChange={handleAmountInput} placeholder="Amount" />
          </div>
          <div className="control">
            <a className="button is-info" onClick={updateAmount}>
              <span className="icon is-right">
                <i className="fas fa-sync"></i>
              </span>
            </a>
          </div>
        </div>
      </td>
      <td>
        <Price priceGross={props.release.priceGross} priceNet={props.release.priceNet} />
      </td>
      <td>
        <Price priceGross={props.release.priceGross} priceNet={props.release.priceNet} amount={props.release.amount}/>

      </td>

    </>
  )
}

export default connect()(WishItem)