import React from 'react'
import Price from './Price'
import {connect} from 'react-redux'
import { setAmountCartItem, deleteCartItem } from '../redux/actions/cart';

const CartItem = (props) => {

  const [amount, setAmount] = React.useState(props.release.amount);


  const handleAmountInput = (e) => {
    let v = e.target.value;
    setAmount(parseInt(v))
  }

  const updateAmount = () => {
    props.dispatch(setAmountCartItem(props.release, amount))
  }

  const handleDelete = () => {
    props.dispatch(deleteCartItem(props.release))
  }

  return (
    <>
      <td>
        <button className="button" onClick={handleDelete}>
        <span className="icon is-right">
                <i className="fas fa-trash"></i>
              </span>
        </button>
      </td>
      <th>
        <figure className="image  is-48x48">
          <img src={'https://couch.inchbyinch.eu/catalog/' + props.release.id + '/' + props.release.coverFront} alt={props.release.text}></img>
        </figure>
      </th>
      <td>
        {props.release.partNumber}
      </td>
      <td>
        {props.release.name}
      </td>
      <td>
        {props.release.text}
      </td>
      <td className="has-text-right">
        <div className="field has-addons">
          <div className="control">
            <input className="input amount-input" type="number" min={1}  max={props.release.stock} value={amount} onChange={handleAmountInput} placeholder="Amount" />
          </div>
          <div className="control">
            <a className="button is-info" onClick={updateAmount}>
              <span className="icon is-right">
                <i className="fas fa-sync"></i>
              </span>
            </a>
          </div>
        </div>
      </td>
      <td className="has-text-right	is-family-monospace	">
        <Price priceGross={props.release.priceGross} priceNet={props.release.priceNet}/>
      </td>
      <td className="has-text-right	is-family-monospace	">
        <Price priceGross={props.release.priceGross} priceNet={props.release.priceNet} amount={props.release.amount}  />
      </td>
    </>
  )
}

export default connect()(CartItem)