import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../redux/actions/auth'
import { setCart, setWish } from '../redux/actions/cart'

const AuthBar = (props) => {
  let cartLength = 0
  let wishLength = 0


  Object.values(props.wish).forEach(i => {
    wishLength = wishLength + i.amount
  })
  Object.values(props.cart).forEach(i => {
    cartLength = cartLength + i.amount
  })

  const handleLogout = () => {
    props.dispatch(logout())
    setTimeout(()=>{
      console.log("now deleting carts and wishes")
      props.dispatch(setCart({}))
      props.dispatch(setWish({}))

    }, 1000)
  }


  if (!props.authenticated) {
    return (
      <>
      <Link to="/login" >
        <button className="button">
          <span className="icon">
            <i className="fas fa-user"></i>
          </span>
          <span>Sign In</span>
        </button>
      </Link>

      <Link to="/register" >
        <button className="button is-info ml-3">
          <span className="icon">
            <i className="fas fa-user"></i>
          </span>
          <span>Register</span>
        </button>
      </Link>
      </>
    )
  } else {
    return (
      <>
        <Link to="/user" >
          <button className="button mx-3">
            <span className="icon">
              <i className="fas fa-user"></i>
            </span>
            <span>Account</span>
          </button>
        </Link>
        <Link to="/login" >
          <button className="button" onClick={handleLogout}>
            <span className="icon">
              <i className="fas fa-user"></i>
            </span>
            <span>Logout</span>
          </button>
        </Link>
        {(props.user.type === "shop" || props.user.type === "privat") && (
          <>
          <Link to="/wishlist" >
            <button className="button mx-3">
              <span title="Badge top right" className="badge is-info">{wishLength}</span>
              <span className="icon">
                <i className="fas fa-star"></i>
              </span>
              <span>Wishlist</span>
            </button>
          </Link>
          <Link to="/cart" >
            <button className="button">

                <span className="icon">
                  <i className="fas fa-shopping-cart"></i>
                </span>
                <span>Cart</span>
                <span title="Badge top right" className="badge is-info">{cartLength}</span>

            </button>
          </Link>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  cart: state.cart.items,
  wish: state.cart.wish
})

export default connect(mapStateToProps)(AuthBar)