import React from 'react'


const Stock = (props) => {
  let stock = parseInt(props.stock);

  if(props.presale){
    return <div className="stock">  Preorder <div className="stock-circle has-background-primary"></div>&nbsp;  {props.releaseDate}</div>
  }


  if(stock > 10){
    return <div className="stock">  In Stock  <div className="stock-circle has-background-success"></div>&nbsp;  {props.releaseDate}</div>
  } else if( stock <= 0){
    return <div className="stock">  Not in Stock <div className="stock-circle has-background-danger"></div>&nbsp;  {props.releaseDate}</div>
  } else if( stock <= 10){
    return <div className="stock">  Few Copys Left <div className="stock-circle has-background-warning"></div> &nbsp; {props.releaseDate}</div>
  } else {
    return <div>ERROR</div>
  }
}


export default Stock