import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { api } from '../api'
import axios from 'axios'
import { refreshLogin } from '../redux/actions/auth'

const EditUser = (props) => {
  const history = useHistory()
  const registerForm = React.useRef()

  // const [type, setType] = React.useState("error")
  const [country, setCountry] = React.useState(props.user.mainAddress.country.id)
  const [billingCountry, setBillingCountry] = React.useState(props.user.billingAddress.country.id)
  const [countries, setCountries] = React.useState([])
  const [same, setSame] = React.useState(props.user.billingAddress ? true:false)

  const [detailError, setDetailError] = React.useState("")

  const type = props.user.type
  React.useEffect(() => {
    async function fetchData() {
      let res = await axios(api + '/countries')
      setCountries(res.data)
    }
    fetchData()
  }, [])



  const handleDetails = async () => {
    var formData = new FormData(registerForm.current);
    formData.append('id', props.user.id)
    formData.append('type', props.user.type)
    if(props.user.mainAddress){
      formData.append('mainAddressId', props.user.mainAddress.id)
    }
    if(same && props.user.billingAddress){
      formData.append('billingAddressId', props.user.billingAddress.id)
    }

    if(same && !props.user.billingAddress){
      formData.append('newBillingAddress', true)
    }

    let ok = await axios({
      method: 'post',
      url: api + '/user/edit',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    console.log(ok.data)

    if (ok.data.success) {
      props.dispatch(refreshLogin())
      history.push('/user')
    } else {
      // todo: present error
      setDetailError(ok.data.error)
    }
  }


  const handleSelectCountry = (e) => {
    let smonth = e.target.value
    setCountry(smonth)
    // props.dispatch(filterByMonth(smonth))
  }
  const handleSelectBillingCountry = (e) => {
    let smonth = e.target.value
    setBillingCountry(smonth)
    // props.dispatch(filterByMonth(smonth))
  }

  const handleSame = e => {
    console.log(e.target.checked)
    setSame(e.target.checked)
  }



  return (
    <>
      <h2 className="is-size-2 has-text-weight-bold">Edit Account Details</h2><br/>
      <form ref={registerForm} onSubmit={(e) => e.preventDefault()} className="mw-400">
        <h2 className="is-size-4">Contact</h2>

        {(type === "privat" || type === "artist") && (
          <div className="field">
            <p className="control ">
              <input className="input" type="text" name="name" defaultValue={props.user.name} placeholder="Name" />
            </p>
          </div>
        )}


        {type === "shop" && (
          <>
            <div className="field">
              <p className="control ">
                <input className="input" type="text" name="name" defaultValue={props.user.name} placeholder="Company Name" />
              </p>
            </div>
            <div className="field">
              <p className="control ">
                <input className="input" type="text" name="name2" defaultValue={props.user.name2} placeholder="Additional Name (optional)" />
              </p>
            </div>
            <div className="field">
              <p className="control ">
                <input className="input" type="text" name="vatNumber" defaultValue={props.user.vatNumber} placeholder="VAT-ID" />
              </p>
            </div>
          </>
        )}

        <div className="field">
          <p className="control ">
            <input className="input" type="phone" name="phone" defaultValue={props.user.phone} placeholder="Phone (optional)" />
          </p>
        </div>
        <br />

        {(type === "shop" || type === "privat") && (
          <>

            <h2 className="is-size-4">Shipping Address</h2>
            <div className="field">
              <p className="control ">
                <input className="input" type="text" name="street" defaultValue={props.user.mainAddress.street} placeholder="Street" />
              </p>
            </div>
            <div className="field">
              <p className="control ">
                <input className="input" type="text" name="city" defaultValue={props.user.mainAddress.city} placeholder="City" />
              </p>
            </div>
            <div className="field">
              <p className="control ">
                <input className="input" type="text" name="zip" defaultValue={props.user.mainAddress.zip} placeholder="ZIP" />
              </p>
            </div>
            <div className="field">
              <div className="select is-fullwidth">
                <select name="country" onChange={handleSelectCountry} value={country} defaultValue={props.user.mainAddress.country.id} placeholder="Country" >
                  {countries.map(c => (<option key={c.id} value={c.id}>{c.name}</option>))}

                </select>
              </div>
            </div>
            <div className="field">
              <label className="checkbox">
                <input name="useBillingAddress" type="checkbox" defaultChecked={same} onChange={handleSame} />
                &nbsp;Use different Billing Address
              </label>
            </div>
            {same && (
              <>
                <br />
                <h2 className="is-size-4">Billing Address</h2>
                <div className="field">
                  <p className="control ">
                    <input className="input" type="email" name="billingEmail" defaultValue={props.user.billingEmail} placeholder="Billing E-Mail (optional)" />
                  </p>
                </div>
                <div className="field">
                  <p className="control ">
                    <input className="input" type="text" name="billingStreet" defaultValue={props.user.billingAddress.street} placeholder="Street" />
                  </p>
                </div>
                <div className="field">
                  <p className="control ">
                    <input className="input" type="text" name="billingCity" defaultValue={props.user.billingAddress.city} placeholder="City" />
                  </p>
                </div>
                <div className="field">
                  <p className="control ">
                    <input className="input" type="text" name="billingZip" defaultValue={props.user.billingAddress.zip} placeholder="ZIP" />
                  </p>
                </div>
                <div className="field">
                  <div className="select is-fullwidth">
                    <select name="billingCountry" onChange={handleSelectBillingCountry} value={billingCountry} defaultValue={props.user.billingAddress.country.id} placeholder="Country" >
                      {countries.map(c => (<option key={c.id} value={c.id} >{c.name}</option>))}

                    </select>
                  </div>
                </div>
              </>
            )}
          </>
        )}


        <div className="field">
          <p className="control">
            <button className="button is-primary" onClick={handleDetails}>
              Submit
            </button>
          </p>
        </div>
        <p className="has-text-danger">{detailError}</p>
      </form>


    </>
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  message: state.auth.message
})

export default connect(mapStateToProps)(EditUser)