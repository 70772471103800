import React from 'react'
import { connect } from 'react-redux'
import { login, setAuthMessage } from '../redux/actions/auth'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'
import {api} from '../api'

const Login = (props) => {
  const history = useHistory()
  const loginForm = React.useRef()
  const resetForm = React.useRef()
  const [forgot, setForgot] = React.useState(false)


  const handleLogin = () => {
    var formData = new FormData(loginForm.current);
    props.dispatch(login(formData))
  }
  const handleReset = async () => {
    var formData = new FormData(resetForm.current);
    let ok = await axios.post(api + '/reset', formData);
    // console.log(ok)
    props.dispatch(setAuthMessage(ok.data.message));
    // history.push('/reset')
  }
  
  const handleResetClick = () => {
    setForgot(true)
  }
  
  if(props.authenticated){
    history.push('/releases')
  }

  return (
    <>
      {!forgot && (
        <>
          <h1 className="is-size-2">Login</h1><br />
          <form ref={loginForm} onSubmit={(e) => e.preventDefault()} className=" mw-400">
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input className="input" type="email" name="mail" placeholder="Email" />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input className="input" type="password" name="password" placeholder="Password" />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button disabled={props.loading} className="button is-primary" onClick={handleLogin}>
                  {props.loading ? "Signing In...": "Sign In"}
            </button>
              </p>
            </div>
            <p className="has-text-danger">{props.message}</p>
          </form>
          <br />
          <p>Forgot <span onClick={handleResetClick} className="has-text-weight-bold"> <Link to="/login">Password?</Link></span></p>
          <p>Do not have an Account? <span className="has-text-weight-bold"><Link to="/register">Register here!</Link></span></p>
        </>

      )}
      {forgot && (
        <>
          <h1 className="is-size-2">Reset Password</h1><br />
          <form ref={resetForm} onSubmit={(e) => e.preventDefault()} className=" mw-400">
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input className="input" type="email" name="mail" placeholder="Email" />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-primary" onClick={handleReset}>
                  Send Reset Link
                </button>
              </p>
            </div>
            <p className="has-font-weight-bold">{props.message}</p>
          </form>
        </>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  message: state.auth.message,
  loading: state.auth.loading
})

export default connect(mapStateToProps)(Login)