import React from 'react';
import Layout from './components/Layout';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";


import Home from './pages/Home'
import Location from './pages/Location'
import Shop from './pages/Shop';
import Releases from './pages/Releases';
import Login from './pages/Login';
import Register from './pages/Register';
import Cart from './pages/Cart';
import Label from './pages/Label';
import Wishlist from './pages/Wishlist';
import ReleasePage from './pages/ReleasePage';
import Reset from './pages/Reset';
import Contact from './pages/Contact';
import Imprint from './pages/Imprint';
import User from './pages/User';
import PromoPage from './pages/PromoPage';
import FeedbackPage from './pages/FeedbackPage';
import EditUser from './pages/EditUser';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route path="/releases">
            <Releases />
          </Route>
          <Route path="/release/:id">
            <ReleasePage />
          </Route>
          <Route path="/promo/:id">
            <PromoPage />
          </Route>
          <Route path="/feedback/:id">
            <FeedbackPage />
          </Route>
          <Route path="/location">
            <Location />
          </Route>
          <Route path="/shop">
            <Shop />
          </Route>
          <Route path="/label">
            <Label/>
          </Route>
          <Route path="/login">
            <Login/>
          </Route>
          <Route path="/reset/:key">
            <Reset/>
          </Route>
          <Route path="/register">
            <Register/>
          </Route>
          <Route path="/cart">
            <Cart/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route path="/wishlist">
            <Wishlist/>
          </Route>
          <Route path="/imprint">
            <Imprint/>
          </Route>
          <Route path="/user/edit">
            <EditUser/>
          </Route>
          <Route path="/user">
            <User/>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
